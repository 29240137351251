
import Intro from "./components/Intro.vue";

export default {
  components: {
    Intro,
  },

  computed: {
    loading: function () {
      return false;
    },

    header: function () {
      return this.$t("RENTAL_TITLE");
    },

    keyFigures: function () {
      return [];
    },
  },
};
