
import Header from "../components/Header/Header.vue";

export default {
  title() {
    return "Oversigt";
  },

  components: {
    Header,
  },

  computed: {
    loading: function () {
      return false;
    },

    coordinates() {
      return this.$store.getters["areas/getCoordinates"];
    },
  },
};
