
import PropertyCard from "~/components/EntityCard/AreaCard.vue";

export default {
  name: "Dashboard",

  components: {
    PropertyCard,
  },

  props: {
    header: {
      type: String,
      required: true,
    },

    keyFigures: {
      type: Array,
      required: true,
    },
  },
};
